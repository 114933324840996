import React, { useContext } from 'react'
import { Contexto } from './contexto/Contexto'

const imgSpain = '/img/spain.jpg';
const imgUk = './img/uk.png';

export default function Caja1() { 
    const {idioma}=useContext(Contexto);
    const {setIdioma}=useContext(Contexto);
   
  /*   Para no repetir codigo creo esta funcion */
    const sustituir= (posicion)=>{
      setIdioma(
        idioma.map((item, index) => {
          if (index === 2) {
            return { ...item, idioma: posicion }; //crea una copia del array cambiando el idioma al de la posicion q sea
          }else{
             return item;
          }
            })
      )
    }
  
    const banderaSpain = () => {
        sustituir(0);
    }
  
    return (
      <div className='divUno d-flex justify-content-center'>
        <img className='medida rounded-5 mt-3 mx-3' src={imgSpain} alt="Spain" onClick={banderaSpain}/>
        <img className='medida rounded-5 mt-3 mx-3' src={imgUk} alt="Uk" onClick={ ()=>{
      setIdioma(
        idioma.map((item, index) => {
          if (index === 2) {
            return { ...item, idioma: 1 };
          }else{
             return item;
          }
            })
      )
          } }/>
      </div>
    )
  }
  
