
import './App.css';
import Header from './CurriculumV';
import Body from './Idiomas';
import { Context } from './contexto/Contexto';


function App() {
  return (
    <>

      <div className=" container-fluid py-5 rounded-5 altura">
    
        <div className='container-md  rounded-5 altura ml-5 blanco shadow-lg'>
          <Context>  
            <Body />
            <Header />
          </Context>
        </div>
      </div>

    </>
  );
}

export default App;
