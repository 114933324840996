import React, { useContext } from 'react';
import './Style.css';
import { Contexto } from './contexto/Contexto';

export default function Header() {
    const { idioma } = useContext(Contexto);
    const firstIdioma = idioma[2].idioma;
    const skills = idioma[firstIdioma].skills;

    return (
        <>
            <div className='header mb-3'>
                <h1 className='text-center pt-4'>
                    <span className='fw-bold'>S</span>heila <span className='fw-bold'>M</span>iguel <span className='fw-bold'>G</span>adea
                </h1>
                <p className='text-center fst-italic verde pb-3'>
                    <h5>{idioma[firstIdioma].carrera}</h5>
                </p>
            </div>
            <div className='row'>
                <div className='mb-3 col-md-12 col-lg-8'>
                    <h5 className='text-center versalita'>{idioma[firstIdioma].titulo}</h5>
                    <p className='text-justify m-3' dangerouslySetInnerHTML={{ __html: idioma[firstIdioma].resumen }} />
                </div>
                <div className='mb-3 col-12 col-lg-4 d-flex justify-content-center align-items-center pb-5'>
                    <div className='gris'>
                        <div className='card-body p-4'>
                            <h5 className='card-title versalita'>{idioma[firstIdioma].tituloContacto}</h5>
                            <p className='card-text' dangerouslySetInnerHTML={{ __html: idioma[firstIdioma].contacto }} />
                        </div>
                    </div>
                </div>
                <div className='mb-3 col-12 col-md-8'>
                    <h5 className='text-center versalita'>{idioma[firstIdioma].tituloExperiencia}</h5>
                    <p className='px-4' dangerouslySetInnerHTML={{ __html: idioma[firstIdioma].experiencia }} />
                </div>
                <div className='mb-3 col-12 col-md-4'>
                    <h5 className='text-center versalita'>{idioma[firstIdioma].tituloEducacion}</h5>
                    <p dangerouslySetInnerHTML={{ __html: idioma[firstIdioma].educacion }} />
                </div>
                <div className='mb-5 col-lg-12 col-xl-8'>
                    <h5 className='text-center versalita'>{idioma[firstIdioma].tituloSkills}</h5>
                    <div className='row'>
                        {skills.map((skill, index) => (
                            <div key={skill.name} className='col-6 mb-3'>
                                <p className='fw-bold text-secondary'>{skill.name}</p>
                                <div className='progress '>
                                    <div
                                        className='progress-bar verdeBackground text-dark fw-bold'
                                        role='progressbar'
                                        style={{ width: skill.level }}
                                        aria-valuenow={skill.level.replace('%', '')}
                                        aria-valuemin='0'
                                        aria-valuemax='100'
                                    >
                                        {skill.level}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className='d-none col-xl-4 d-xl-block '>
                    <div className="body-container gris d-none  d-xl-block ">
                        <img src="img/sheila.png" alt="Decorative" className="floating-image shadow-effect" />
                        <div className='d-none d-xl-block '>
                            <p className="pe-3 fst-italic versalita text-end fw-bold d-none d-xl-block"> 2024 - React proyect</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
