import React, { createContext, useState} from 'react'

/* crea el contexto */
export const Contexto = createContext(); 

/* TODOS LOS HIJOS QUE ESTEN DETRO DE DATOS, PUEDAN ACCEDER A ESAS OPCIONES, ES decodeURI, TODOS ACCEDEN AL COLOR Y A PODER CAMBIAR ESE COLOR */
export const Context = ({ children}) => {
   
  const skillsData = [
    { name: 'HTML 5', level: '100%' },
    { name: 'CSS (Grid, Flexbox)', level: '95%' },
    { name: 'JavaScript', level: '85%' },
    { name: 'PHP', level: '85%' },
    { name: 'MySQL', level: '90%' },
    { name: 'Java', level: '70%' },
    { name: 'Python', level: '60%' },
    { name: 'React', level: '50%' },
    { name: 'Symfony', level: '70%' },
    { name: 'VBA', level: '90%' },
    { name: 'Git', level: '75%' },
    { name: 'Bootstrap ', level: '90%' },
    { name: 'Tailwind', level: '90%' },
    { name: 'WordPress (Divi)', level: '80%' },
  ];

     const valores=[{
      carrera:"Desarrolladora Web - Full Stack",
      titulo:"Resumen Profesional",
      resumen:"Soy una <strong>desarrolladora web junior</strong> apasionada por la programación, con un interés especial tanto en el frontend como en el backend. Mi enfoque orientado a resultados y mi capacidad para resolver problemas complejos me han permitido avanzar rápidamente en mi carrera en el desarrollo web. <br> Desde que descubrí mi pasión por la programación, he trabajado incansablemente para mejorar mis habilidades, y actualmente me encuentro aprendiendo <em>React</em> para ampliar mis conocimientos y fortalecer mis capacidades en el desarrollo de aplicaciones web modernas e innovadoras. <br> Estoy entusiasmada por la oportunidad de seguir creciendo profesionalmente y aplicar mis conocimientos en proyectos reales.",
      tituloContacto:"Contacto",
      contacto: "<br> 📞 683574640<br><br>📍 Fuenlabrada<br><br>📧 sheilamiggad@gmail.com<br><br>💻 <a href='https://sheilamiggad.com' target='_blank' class='text-dark fst-italic text-decoration-none'>sheilamiggad.com</a>",
      tituloExperiencia:"Experiencia",
      experiencia:"<span class='verde'>2022 - Act </span> <strong>Freelance (proyectos individuales)</strong><br><br> <span class='verde'>2024 </span>  <strong>Prácticas en Imagar (Alcorcón)</strong><br> <p class='tabulacion'><em >Estuve de practicas durante 3 meses, aprendiendo y realizando proyectos en Symfony (Framework de PHP)</em></p>  <span class='verde'>2018 - Act</span> <strong>Profesora de Bádminton</strong><br><em> <p class='tabulacionAct'>Imparto clases de bádminton en dos institutos, en la localidad de Humanes, y otro en Loranca (Fuenlabrada).</em></p> <span class='verde'>2021 </span> <strong>Profesora de PROA en el colegio C.E.I.P La Cañada.</strong><br><p class='tabulacion'><em> Imparto clases de lengua, matemáticas e inglés en un colegio en la localidad de Fuenlabrada (Loranca).</em></p><span class='verde'>2021 </span><strong><em>Monitora de campamento en el colegio C.E.I.P La Cañada.</em></strong> ",
      tituloEducacion:"Educación",
      educacion:"<strong>Grado superior de Desarrollo de Aplicaciones Web.</strong><br><em>CDM Escuela de Formaciones Digitales</em><br><br><strong> Grado en Educación Primaria con Mención en EF.</strong><br><em>Universidad Rey Juan Carlos</em><strong><br><br>Bachillerato Tecnológico. </strong><br><em>IES Barrio Loranca </em><strong><br><br>Graduado E.S.O </strong><br><em> IES Barrio Loranca</em>",
      tituloSkills:"Habilidades",
      skills: skillsData
  },{
    carrera:"Web Developer - Full Stack",
    titulo:"Professional summary",
    resumen:"I am a <strong>junior web developer</strong> passionate about programming, with a particular interest in both frontend and backend development. My results-oriented approach and ability to solve complex problems have allowed me to advance quickly in my web development career. <br> Since discovering my passion for programming, I have worked tirelessly to improve my skills, and I am currently learning <em> React </em> to expand my knowledge and strengthen my capabilities in developing modern and innovative web applications. <br> I am excited about the opportunity to continue growing professionally and to apply my knowledge to real-world projects.",
    tituloContacto:"Contact",
    contacto: "<br> 📞 683574640<br><br>📍 Fuenlabrada<br><br>📧 sheilamiggad@gmail.com<br><br>💻 <a href='https://sheilamiggad.com' target='_blank' class='text-dark fst-italic text-decoration-none'>sheilamiggad.com</a>",
    tituloExperiencia:"Work History",

    experiencia:"<span class='verde'>2022-Present</span> <strong>Freelance (individual projects)</strong><br><br><span class='verde'>2024</span> <strong>Internship at Imagar (Alcorcón)</strong><br><p class='tabulacion'><em>I was an intern for 3 months, learning and doing projects in Symfony (PHP Framework)</em></p><span class='verde'>2018-Present</span> <strong>Badminton's teacher</strong><br><p class='tabulacionPresent'><em>I teach badminton at two schools, one in Humanes and another in Loranca (Fuenlabrada).</em></p><span class='verde'>2021</span> <strong>PROA Teacher at C.E.I.P La Cañada</strong><br><p class='tabulacion'><em>I taught language, mathematics, and English at a school in Fuenlabrada (Loranca).</em></p><span class='verde'>2021</span> <strong><em> School camp  at C.E.I.P La Cañada.</em></strong>",
    tituloEducacion:"Education",
    educacion:"<strong>Web Application Development</strong><br><em>CDM Escuela de Formaciones Digitales</em><br><br><strong>Degree in Primary Education with a mention in Physical Education</strong><br><em>Rey Juan Carlos University</em><br><br><strong>Technological High School</strong><br><em>IES Barrio Loranca</em><br><br><strong>Secondary School Diploma (E.S.O.)</strong><br><em>IES Barrio Loranca</em>",
    tituloSkills:"Skills",
    skills: skillsData
  },{
      idioma:0
  }]
  const [idioma, setIdioma] = useState(valores); 

  return (
   /* ESTO HACE Q LOS HIJOS PUEDAN ACCEDER A ESTOS DATOS( COLOR Y SETCOLOR) */
    <Contexto.Provider value={{idioma, setIdioma}}>
    {children}
    </Contexto.Provider>
   
  )
}
